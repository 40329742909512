import Image from "../assets/bulb.png";

const Bulb = () => {
  return (
    <div className="bulb">
      <img src={Image} alt="" />
    </div>
  );
};

export default Bulb;
